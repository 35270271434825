import { DataService } from "services/data-service";
import { autoinject, TaskQueue } from "aurelia-framework";
import * as Quill from "quill";

@autoinject
export class Diagnostics {
    private _dataService: DataService;
    _taskQueue: TaskQueue;

    diagnostics = [];

    diagnosticSearchTerm: string = "";

    isLoadingDiagnostics: boolean = false;

    constructor(dataService: DataService, taskQueue: TaskQueue) {
        this._dataService = dataService;
        this._taskQueue = taskQueue;
    }

    activate() {
        this.isLoadingDiagnostics = true;
        this._dataService.getDiagnostics(null).then((data) => {
            console.log(data);
            this.diagnostics = data;
            this.isLoadingDiagnostics = false;
        });
    }

    async toggleExtraDetails(diagnostic) {
        if (diagnostic.areExtraDetailsOpen) {
            await this._dataService.updateDiagnostic(diagnostic);
        }
        diagnostic.areExtraDetailsOpen = !diagnostic.areExtraDetailsOpen;
        this._taskQueue.queueMicroTask(() => {
            this.createEditors(diagnostic);
        });
    }

    createEditors(diagnostic) {
        if (diagnostic.hasQuillEditors) return;
        var options = {
            theme: "snow",
            outerHeight: "150px",
            innerHeight: "150px",
        };

        var indicationEditor = new Quill(
            `#indication-${diagnostic.code}`,
            options
        );
        indicationEditor.on("text-change", (delta, oldDelta, source) => {
            diagnostic.indication = indicationEditor.root.innerHTML;
        });
        if (diagnostic.indication != null) {
            indicationEditor.pasteHTML(diagnostic.indication);
        }

        var notesEditor = new Quill(`#notes-${diagnostic.code}`, options);
        notesEditor.on("text-change", (delta, oldDelta, source) => {
            diagnostic.notes = notesEditor.root.innerHTML;
        });
        if (diagnostic.notes != null) {
            notesEditor.pasteHTML(diagnostic.notes);
        }

        var protocolEditor = new Quill(`#protocol-${diagnostic.code}`, options);
        protocolEditor.on("text-change", (delta, oldDelta, source) => {
            diagnostic.protocol = protocolEditor.root.innerHTML;
        });
        if (diagnostic.protocol != null) {
            protocolEditor.pasteHTML(diagnostic.protocol);
        }

        diagnostic.hasQuillEditors = true;
    }
}
