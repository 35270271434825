import { autoinject, TaskQueue } from "aurelia-framework";
import { IAccession, IDashboardAccessions } from "interfaces/entity-interfaces";
import { DataService } from "services/data-service";
import Swal from "sweetalert2";
import * as JsBarcode from "jsbarcode";
import { Router } from "aurelia-router";

@autoinject
export class Dashboard {
    private _dataService: DataService;
    private _taskQueue: TaskQueue;

    accessions: IDashboardAccessions;
    viewingAccession: boolean = false;
    accession: any;
    _router: Router;

    constructor(
        dataService: DataService,
        taskQueue: TaskQueue,
        router: Router
    ) {
        this._dataService = dataService;
        this._taskQueue = taskQueue;
        this._router = router;
    }

    async activate() {
        const returnedData =
            await this._dataService.getDashboardDataFromServerAsync({});

        this.accessions = returnedData;

        console.log("Accessions", this.accessions);
    }

    deleteAccession(accession) {
        Swal.fire({
            icon: "error",
            title: "Delete Accession",
            text: `Are you sure you want to delete accession ${accession.accessionId}?`,
            showDenyButton: true,
            confirmButtonText: `Cancel`,
            denyButtonText: `Yes, Delete`,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
            } else if (result.isDenied) {
                console.log("DELETING");
                //Delete this MF
                await this._dataService.deleteAccession(accession.accessionId);

                Swal.fire({
                    icon: "success",
                    title: "Accession Deleted",
                });
                const returnedData =
                    await this._dataService.getDashboardDataFromServerAsync({});
                this.accessions = returnedData;
            }
        });
    }

    viewAccession(accession) {
        this.accession = accession;
        this.viewingAccession = true;
    }

    navigateToLabResult(accession) {
        this._router.navigateToRoute("lab-result", {
            accessionId: accession.accessionId,
        });
    }

    async printAccession(accession) {
        this._router.navigateToRoute("print-lab-requisition", {
            id: accession.accessionId,
        });
    }
    printRequisition() {
        window.print();
    }
    closeAccession() {
        this.viewingAccession = false;
        this.accession = null;
    }
    getFullPatientSex(short) {
        switch (short) {
            case "M":
                return "Male";
            case "F":
                return "Female";
            default:
                "";
        }
    }
    getFullPatientNeutered(short) {
        switch (short) {
            case "Y":
                return "Neutered";
            case "N":
                return "Not Neutered";
            default:
                "";
        }
    }
}
